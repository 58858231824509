<template>
  <div class="pt-7">
    <v-card class="d-flex rounded-lg py-2 pr-3 align-center">
      <place-search
        :search-types="searchTypes"
        hiddenItemDetails
        placeholder="Qualquer local"
        class="flex-shrink-1 pt-0"
        v-model="place"
        rounded
        style="max-width: 250px"
      />
      <v-text-field
        rounded
        hide-details
        placeholder="Nome, descrição, organização"
        v-model="search"
        class="pt-0 flex-grow-1"
        @keypress.enter="runSearch"
      />
      <v-btn
        icon
        color="primary"
        :loading="loading || inactiveLoading"
        large
        @click="runSearch"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-card>

    <p
      class="text-center mt-2"
      :class="{ 'mt-8': $vuetify.breakpoint.smAndDown }"
    >
      {{ searchText }}
    </p>

    <template v-if="!results">
      <v-row>
        <v-col v-for="i in 3" :key="i" cols="12" sm="6" md="4">
          <v-card elevation="0" rounded="lg" outlined>
            <v-img src="" :aspect-ratio="16 / 9" class="rounded-t-lg">
              <template slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  height="100%"
                  class="rounded-b-0"
                />
              </template>
            </v-img>
            <v-card-text class="pb-2">
              <v-skeleton-loader type="heading" width="180px" />
              <div class="d-flex align-center justify-space-between gap-4 mt-2">
                <span class="text-center">
                  <v-skeleton-loader type="text" width="110px" />
                </span>
                <v-skeleton-loader type="text" width="130px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <cols-organizer
        :cols="1"
        :sm="2"
        :md="3"
        :items="sortedResults.firstParty.slice(0, componentPosition.artists)"
      >
        <template v-slot="{ item: results }">
          <party-item :party="results"></party-item>
        </template>
      </cols-organizer>

      <artists />

      <cols-organizer
        class="mt-4"
        :cols="1"
        :sm="2"
        :md="3"
        :items="sortedResults.firstParty.slice(componentPosition.artists)"
      >
        <template v-slot="{ item: results }">
          <party-item :party="results"></party-item>
        </template>
      </cols-organizer>
    </template>

    <v-card
      v-if="sortedResults.thirdParty.length > 0"
      :class="[darkMode ? 'dark-card' : 'light-card']"
      class="rounded-xl py-3 px-3 mt-8"
      elevation="0"
      outlined
    >
      <div class="px-2">
        <h4 :class="[darkMode ? 'text-white' : 'text-black']" class="mb-0">
          Outros Eventos
        </h4>
        <p :class="[darkMode ? 'text-gray-400' : 'text-black']">
          Descubra eventos de outras plataformas parceiras que podem chamar sua
          atenção.
        </p>
      </div>
      <div>
        <cols-organizer
          :cols="1"
          :sm="2"
          :md="3"
          :items="sortedResults.thirdParty"
        >
          <template v-slot="{ item: inactiveResults }">
            <party-item outlined :party="inactiveResults"></party-item>
          </template>
        </cols-organizer>
      </div>
    </v-card>
    <div v-if="inactiveResults != false" class="px-2 mt-8">
      <h4 class="mb-0">Eventos anteriores</h4>
      <p>Veja aqui os eventos que já rolaram!</p>
    </div>

    <template v-if="!inactiveResults">
      <v-row v-intersect="onIntersect">
        <v-col v-for="i in 3" :key="i" cols="12" sm="6" md="4">
          <v-card elevation="0" rounded="lg" outlined>
            <v-img src="" :aspect-ratio="16 / 9" class="rounded-t-lg">
              <template slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  height="100%"
                  class="rounded-b-0"
                />
              </template>
            </v-img>
            <v-card-text class="pb-2">
              <v-skeleton-loader type="heading" width="180px" />
              <div class="d-flex align-center justify-space-between gap-4 mt-2">
                <span class="text-center">
                  <v-skeleton-loader type="text" width="110px" />
                </span>
                <v-skeleton-loader type="text" width="130px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <div v-else>
      <cols-organizer :cols="1" :sm="2" :md="3" :items="sortedInactiveResults">
        <template v-slot="{ item: inactiveResults }">
          <party-item :party="inactiveResults"></party-item>
        </template>
      </cols-organizer>
    </div>

    <v-alert v-if="!isAuthenticated" prominent type="info" class="mt-8" text>
      <div class="d-flex flex-wrap justify-space-between align-center">
        <div>
          <b>Não encontrou o evento que procurava?</b>
          <br />
          Tente entrar na sua conta para ver eventos que você foi convidado.
        </div>
        <v-btn color="primary" @click="login">entrar</v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import { mapGetters } from "vuex";

import PlaceSearch from "../global/PlaceSearch.vue";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
import PartySort from "@/utils/partySortFunction";
import Artists from "@/components/home/Artists.vue";

export default {
  metaInfo: {
    title: "Eventos",
  },
  data: () => ({
    searchTypes: ["administrative_area_level_2", "administrative_area_level_1"],
    search: "",
    place: null,

    loading: false,
    inactiveLoading: false,
    error: null,
    results: false,
    inactiveResults: false,

    componentPosition: {
      artists: 6,
    },

    code: "",
    resultsFilter: {
      search: "",
      place: null,
    },
  }),
  methods: {
    async performSearch(inactiveStatus) {
      const { search, place } = this;
      const { party } = await SHOP.search({
        q: search || undefined,
        state: place?.state || undefined,
        city: place?.city || undefined,
        code: this.code || undefined,
        inactive: inactiveStatus,
      });
      this.resultsFilter = { search, place };
      return party;
    },
    async searchActive() {
      try {
        this.loading = true;
        this.error = null;

        const party = await this.performSearch(false);

        this.results = party;
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },

    async searchInactive() {
      try {
        this.inactiveLoading = true;
        this.error = null;
        const party = await this.performSearch(true);

        this.inactiveResults = party;
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.inactiveLoading = false;
      }
    },

    async runSearch() {
      await this.searchActive();
      if (this.inactiveResults) await this.searchInactive();
    },

    onIntersect(entries) {
      if (this.inactiveResults || this.loading) return;
      if (this.inactiveLoading) return;
      if (entries[0].isIntersecting) this.searchInactive();
    },
    manageCode() {
      var sessionStorageItem = window.sessionStorage.getItem("shop-code") || {};
      this.code = Object.values(sessionStorageItem).join(",");
    },
    login() {
      this.$router.push({ name: "sign-in", query: { redirect: "/shop" } });
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    sortedResults() {
      if (!this.results) return { firstParty: [], thirdParty: [] };
      const groupedResults = this.results.reduce(
        (acc, item) => {
          const hasPlatform = item.Organization?.Platform
            ? "thirdParty"
            : "firstParty";
          acc[hasPlatform].push(item);
          return acc;
        },
        {
          thirdParty: [],
          firstParty: [],
        }
      );
      return {
        firstParty: groupedResults.firstParty.sort(PartySort),
        thirdParty: groupedResults.thirdParty.sort(PartySort),
      };
    },
    sortedInactiveResults() {
      return this.inactiveResults.sort(PartySort);
    },
    searchText() {
      if (this.loading) return `Buscando resultados`;
      var text = "";
      if (this.resultsFilter.search)
        text += ` com o termo "${this.resultsFilter.search}"`;

      if (this.resultsFilter.place)
        text += ` em ${this.resultsFilter.place.name}`;
      const length = this.results.length;
      return `Exibindo ${length} resultado${length == 1 ? "" : "s"}${text}.`;
    },
  },
  watch: {
    place: {
      handler() {
        this.runSearch();
      },
      deep: true,
    },
  },
  components: { PlaceSearch, ColsOrganizer, PartyItem, Artists },
  mounted() {
    this.manageCode();
    this.searchActive();
  },
};
</script>

<style></style>
