<template>
  <div rounded="xl" class="my-8" v-if="artists.length > 0 && !loading">
    <div class="px-2">
      <h4 class="mb-0">Artistas</h4>
      <p class="mb-3">Encontre eventos dos seus artistas favoritos!</p>
    </div>
    <v-carousel
      hide-delimiters
      :show-arrows="artists.length > artistsPerSlide"
      :show-arrows-on-hover="artists.length > artistsPerSlide"
      height="auto"
      cycle
    >
      <v-carousel-item
        v-for="i in Math.ceil(artists.length / artistsPerSlide)"
        :key="i"
        class="rounded-xl"
      >
        <v-row class="mx-1" dense>
          <v-col
            v-for="index in Array.from(
              { length: artistsPerSlide },
              (_, j) => (i - 1) * artistsPerSlide + j
            )"
            :key="artists[index]?.id"
          >
            <v-card
              rounded="xl"
              elevation="2" 
              v-if="artists[index]"
              :to="`/artist/${artists[index]?.slug}`"
            >
              <v-img
                :src="artists[index]?.verticalCoverPhoto"
                :aspect-ratio="8 / 10"
                rounded="xl"
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" width="100%" height="100%" />
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <!-- <v-slide-group v-model="model" class="pa-4" show-arrows>
      <v-slide-item
        v-for="artist in [
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
          ...artists,
        ]"
        :key="artist.id"
      >
        <v-card class="mx-2" rounded="xl">
          <v-img
            :src="artist.verticalCoverPhoto"
            :aspect-ratio="8 / 10"
            height="270px"
            :width="270 * (8 / 10)"
            rounded="xl"
          />
        </v-card>
      </v-slide-item>
    </v-slide-group> -->
  </div>
</template>

<script>
import ARTIST from "@/services/app/artist";

export default {
  name: "Artists",
  data() {
    return {
      index: 0,
      loading: true,
      artists: [],
    };
  },
  mounted() {
    this.fetchArtists();
  },
  methods: {
    async fetchArtists() {
      try {
        const response = await ARTIST.getAllArtists();
        this.artists = response.artists;
      } catch (error) {
        console.error("Erro ao buscar artistas:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    artistsPerSlide() {
      if (this.$vuetify.breakpoint.smAndDown) return 2;
      if (this.$vuetify.breakpoint.mdAndDown) return 3;
      if (this.$vuetify.breakpoint.lgAndDown) return 4;
      return 4;
    },
  },
};
</script>
