import http from "@/http-common";

const getArtistBySlug = async (slug) => {
  return await http
    .get(`/app/artist/${slug}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAllArtists = async () => {
  return await http
    .get("/app/artist")
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getArtistBySlug,
  getAllArtists,
};