<template>
  <div
    class="g-recaptcha"
    style="z-index: 10000"
    :data-sitekey="recaptchaToken"
    data-callback="recaptchaScript"
    data-expired-callback="recaptchaScriptExpired"
    data-error-callback="recaptchaScriptError"
    data-size="invisible"
  ></div>
</template>

<script>
const { VUE_APP_VERCEL_ENV } = process.env;

export default {
  emits: ["success", "error", "expired", "loaded"],
  data() {
    return {
      scriptEl: null,
      observer: null,
      env: VUE_APP_VERCEL_ENV || "development",
      recaptchaToken: process.env.VUE_APP_RECAPTCHA,
    };
  },
  methods: {
    async execute() {
      try {
        if (!this.env === "development") this.$emit("success", "development");

        if (!window.grecaptcha) return this.$emit("error");

        let current = window.grecaptcha.getResponse();
        if (current) {
          window.grecaptcha.reset();
          this.startReCaptchaObservation();
        }
        window.grecaptcha.execute();
      } catch (e) {
        this.$emit("error", e);
      }
    },
    async detectWhenReCaptchaChallengeIsShown() {
      return new Promise(function (resolve) {
        const targetElement = document.body;

        const observerConfig = {
          childList: true,
          attributes: true,
          attributeFilter: ["style"],
          attributeOldValue: false,
          characterData: false,
          characterDataOldValue: false,
          subtree: false,
        };

        function DOMChangeCallbackFunction(mutationRecords) {
          mutationRecords.forEach((mutationRecord) => {
            if (mutationRecord.addedNodes.length) {
              var reCaptchaParentContainer = mutationRecord.addedNodes[0];
              var reCaptchaIframe =
                reCaptchaParentContainer.querySelectorAll("iframe");

              if (reCaptchaIframe.length) {
                var reCaptchaChallengeOverlayDiv =
                  reCaptchaIframe[0].parentNode?.parentNode;
                if (reCaptchaChallengeOverlayDiv) {
                  reCaptchaObserver.disconnect();
                  resolve(reCaptchaChallengeOverlayDiv);
                }
              }
            }
          });
        }

        const reCaptchaObserver = new MutationObserver(
          DOMChangeCallbackFunction
        );
        reCaptchaObserver.observe(targetElement, observerConfig);
      });
    },
    observeChallengeClosed(overlayDiv) {
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          // Verifique se a mutação é para atributos e se o atributo modificado é 'style'
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            let currentStyle = window.getComputedStyle(overlayDiv);

            if (
              currentStyle.visibility === "hidden" &&
              !window.grecaptcha.getResponse()
            ) {
              console.log("reCAPTCHA challenge was closed!");
              // reCaptchaChallengeClosureObserver.disconnect();
              this.onClose();
            }
          }
        });
      });

      this.observer.observe(overlayDiv, {
        attributes: true, // observa mudanças em atributos
        attributeFilter: ["style"], // especificamente o atributo 'style'
      });
    },
    async startReCaptchaObservation() {
      try {
        if (this.observer) this.observer.disconnect();
        const overlayDiv = await this.detectWhenReCaptchaChallengeIsShown();
        this.observeChallengeClosed(overlayDiv);
      } catch (error) {
        console.error("Failed to start observing reCAPTCHA:", error);
      }
    },
    onLoaded() {
      this.$emit("loaded");
      if (this.autoRun) this.execute();
    },
    beforeExecute(response) {
      if (this.observer) this.observer.disconnect();
      this.$emit("success", response);
    },
    onError(response) {
      this.$emit("error", response);
    },
    onClose() {
      this.$emit("close");
    },
    onExpired(response) {
      this.$emit("expired", response);
    },
  },
  mounted() {
    let recaptchaScript = document.createElement("script");

    document.head.appendChild(recaptchaScript);
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=recaptchaScriptLoaded"
    );
    this.scriptEl = recaptchaScript;

    window.recaptchaScript = this.beforeExecute;
    window.recaptchaScriptLoaded = this.onLoaded;
    window.recaptchaScriptError = this.onError;
    window.recaptchaScriptExpired = this.onExpired;

    this.startReCaptchaObservation();
  },
  destroyed() {
    if (this.scriptEl) document.head.removeChild(this.scriptEl);
    if (this.observer) this.observer.disconnect();
  },
  props: {
    autoRun: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
